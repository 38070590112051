<template>
    <b-sidebar
        id="drop-staff-form-sidebar"
        :visible="isStaffFormSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-sm"
        shadow
        backdrop
        no-header
        right
        @shown="getData(Data)"
        
        @change="(val) => $emit('update:is-staff-form-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                  ผู้ช่วยแพทย์
                </h5>
                <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
                />
                

            </div>
            <div class="m-2">
                <label class="mb-1">พนักงาน</label>
                <v-select
                :options="staffs"
                v-model="input.doctor_id"
                :get-option-label="(option) => (option.first_name+' '+option.last_name)"
                :reduce="val => val.id"
                
              
                >
              
            </v-select>
            <b-form-group class="mt-1 form-boder-input">
                <label>รายการ</label>
                <!--<div v-for="c in selecteds" :key="'course-id'+c.id" class="d-flex align-items-center">
                    <b-form-checkbox v-model="input.course_id"  :value="c.id"/>
                    {{  c.title }}
                    <b-form-input v-model="input.qty"   />
                    ครั้ง

                </div>-->
                <b-form-checkbox-group
                    id="checkbox-group-1"
                   v-model="input.course_id"
                    :options="selecteds"
                    value-field="course_id"
                    text-field="title"
                    name="flavour-1"
                ></b-form-checkbox-group>
                
            </b-form-group>
            
            </div>
          
            <div class="d-flex m-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                v-if="input.doctor_id && input.course_id.length > 0"
                @click="onSubmit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                v-else
                disabled
              >
                Submit
              </b-button>
              
            </div>
        </template>
    </b-sidebar>
</template>
<script>
import {
  BDropdown, BDropdownItem, BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormSelectOption,BFormDatepicker,BFormTimepicker,
  BInputGroup,BInputGroupAppend,BInputGroupPrepend,BRow, BCol,BBadge,BFormSpinbutton,BButtonGroup,BFormRadioGroup,BFormRadio,BTooltip,BFormCheckboxGroup
  
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref,onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import userStoreModule from '@/views/users/user-list/userStoreModule'
import queueServiceStoreModule from '../queueServiceStoreModule'
import { integer } from 'vee-validate/dist/rules'
export default {
    components:{
        BDropdown, BDropdownItem, BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormSelectOption,BFormDatepicker,BFormTimepicker,
        BInputGroup,BInputGroupAppend,BInputGroupPrepend,BRow, BCol,BBadge,BFormSpinbutton,BButtonGroup,BFormRadioGroup,BFormRadio,BTooltip,
        vSelect,
        BFormCheckboxGroup

    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isStaffFormSidebarActive',
        event: 'update:is-staff-form-sidebar-active',
    },
    props: {
        isStaffFormSidebarActive: {
            type: Boolean,
            required: true,
        
        },
        Data:{
            type: Object,
            requerd: true
        },
        selecteds:{
            type: Array,
            required:true
        },
       
       

    },
    setup(props, { emit }) {
        const toast = useToast()
        const QUEUE_SERVICE_STORE_MODULE_NAME = 'queue-store';
        const USER_STORE_MODULE_NAME = 'app-users'
        if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
        onUnmounted(() => {
            if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
        });
        if (!store.hasModule(QUEUE_SERVICE_STORE_MODULE_NAME)) store.registerModule(QUEUE_SERVICE_STORE_MODULE_NAME, queueServiceStoreModule)
        onUnmounted(() => {
            if (store.hasModule(QUEUE_SERVICE_STORE_MODULE_NAME)) store.unregisterModule(QUEUE_SERVICE_STORE_MODULE_NAME)
        });
        const staffs = ref([]);
       
       
        const blankData = {
            service_id:'',
            doctor_id:'',
            types:'assistant',
            group_id:'',
            staffs:[],
            course_id:[],
          
           
        };
        const input = ref(JSON.parse(JSON.stringify(blankData)))
        
      
        const getData = async (data)=>{
            console.log('test-service',data);
            input.value.doctor_id = '';
          input.value.service_id = data.service_id;
          input.value.staffs = data.staffs;
          input.value.types = data.types;
          input.value.group_id = data.group_id
           const users = await store.dispatch('app-users/fetchData', {
                     //   group_id:8
                        
                        /* page: currentPage.value,
                        sortBy: sortBy.value,
                        sortDesc: isSortDirDesc.value,
                        status: statusFilter.value,*/
                    });
            if(users.data.success){
                staffs.value = users.data.data.filter(item => {
                   
                    return item;
                    if(item.group_id === input.value.group_id && !input.value.staffs.includes(item.id)){
                            return item;
                    }
                });
               
            }
            //console.log('users',users);
        }
        const onSubmit = async ()=>{
           // console.log('input-staff',input.value);
          const response = await store.dispatch('queue-store/addStaff', input.value);
            emit('refetch-data', input.value.types);
              
            emit('update:is-staff-form-sidebar-active', false);
            toast({
                    component: ToastificationContent,
                props: {
                    title: "Add Staff Success",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                },
                })

        }


        return {
            getData,
            staffs,
            input,
            onSubmit
        }
    }
    
}
</script>