import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
export default function useQueueServiceForm() {
    const toast = useToast()
    const isItemFormSidebarActive = ref(false);
    const isItemNumFormSidebarActive = ref(false);
    const isStaffFormSidebarActive = ref(false);
    const headertext = ref('');
    const ItemData = ref([]);
    const items = ref([]);
    const Detail = ref({});
    const Options = ref(0);
    const doctors = ref([]);
     const assistants = ref([]);
    const selecteds = ref([]);
    const patient_id  = ref(0);
    const id = ref(router.currentRoute.params.id);
    const coureses =ref([]);
    const coureses_num =ref([]);

    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;


    const blankData = {
        service_id:'',
        group_id:8,
        types:'assistant',
        staffs:[]
       
    };
    const Data = ref(JSON.parse(JSON.stringify(blankData)));
    
    const addCourse = ()=>{
        
        ItemData.value = {};
        headertext.value = 'รายครั้ง';
        isItemFormSidebarActive.value = true;
        Options.value = 1;
    }
    const addCourseNum = ()=>{
        
        ItemData.value = {};
        headertext.value = 'ตามจำนวน';
        isItemNumFormSidebarActive.value = true;
        Options.value = 1;
    }
    
    const fetchOpdcardItemData = async ()=>{
      

    }
    const staffsData = async (types)=>{
        console.log('refesh');
        if(types === 'assistant'){
            assistants.value = []
            assistantsData();

        }else{
            doctors.value = []
            DoctorsData();
        }
    }
    const assistantsData = async ()=>{
        //assistants
       
        let response = await store.dispatch('queue-store/fetchStaffData',{
            service_id:id.value,
            types:'assistant'
        });
        console.log('assistants',response);
        if(response.data.success){
            assistants.value = response.data.data;
        }
        
    }
    assistantsData();
    const DoctorsData = async ()=>{
        
        let response = await store.dispatch('queue-store/fetchStaffData',{
            service_id:id.value,
            types:'doctor'
        });
        if(response.data.success){
            doctors.value = response.data.data;
        }
       //console.log('staff',response);
      
 
     }
    const fetchData = async ()=>{
       let response = await store.dispatch('queue-store/getData',id.value);
       console.log('response',response);
       if(response.data.success){
        Detail.value = response.data.data
        patient_id.value = Detail.value.patient_id
        getCourses();
        
        if(Detail.value.items.length > 0){
            selecteds.value = Detail.value.items;
            coursesData1();
            
        }
        

       }

    }
   
    const getCourses = async ()=>{
        coureses.value = [];
        coureses_num.value = [];

        let course =   await store
            .dispatch('app-receipt/getCourses', patient_id.value)
            console.log('cccourses',course);
            if(course.data.success){
                coureses.value = course.data.data.filter(course=>course.type_id==2);
                coureses_num.value = course.data.data.filter(course=>course.type_id==3);
             

            }
    }
    
    const coursesData = async (sels)=>{
        //getItem
        let data = {
            items:sels,
            id:id.value

        }

       await store.dispatch('queue-store/addCourseNum',data);
       selecteds.value = [];
       fetchData();
        /*selecteds.value = sels
        selecteds.value.map(async (value)=>{
            console.log('value',value);
            getItem(value.course_id);
            //course_id
        });*/

    }
    const coursesnumData = async(sels)=>{
        let data = {
            items:sels,
            id:id.value

        }

       await store.dispatch('queue-store/addCourseNum',data);
       selecteds.value = [];
       fetchData();

    }
    const coursesData1 = async ()=>{
        //getItem
        
        selecteds.value.map(async (value)=>{
            console.log('value',value);
            getItem(value.course_id);
            //course_id
        });

    }
    const removeCourse = async (item)=>{
         const ind = coureses.value.findIndex(s => s.course_id === item.course_id);
        const index = selecteds.value.findIndex(s => s.course_id === item.course_id);
        coureses.value[ind].balance += item.balance;
        selecteds.value.splice(index,1);
         items.value =  items.value.filter(function(elem){
            return elem.courses_id != item.course_id; 
         });


    }
    const changeQty = async (item)=>{
        const ind = coureses.value.findIndex(s => s.course_id === item.course_id);
        const index = selecteds.value.findIndex(s => s.course_id === item.course_id);
        if(item.balance <=  coureses.value[ind].balance_default){
            coureses.value[ind].balance = coureses.value[ind].balance_default-item.balance;
           // selecteds.value[index].balance = coureses.value[ind].balance_default;
        }else{
            selecteds.value[index].balance = coureses.value[ind].balance_default;
            coureses.value[ind].balance = 0;
        }
        selecteds.value.map(async (value)=>{
            console.log('value',value);
            getItem(value.course_id);
            //course_id
        });
        //balance_default
       // console.log('item',item);

    }
    const getItem = async (course_id)=>{
        const itms =  await store
            .dispatch('app-stock/fetchStockData', {
                
                service_id:id.value,
                courses_id:course_id,
                is_active:1,
                branch_id:branch_id,
                order_by:'id DESC'
            
            });

        if(itms.data.success){
            itms.data.data.map((itm)=>{
                let index  = items.value.findIndex(s => s.courses_id === itm.courses_id && itm.product_id === s.product_id);
                const ind = selecteds.value.findIndex(s => s.course_id === itm.courses_id);
                console.log('ind',ind);
                console.log('itm',itm);
                 const balance = selecteds.value[ind].balance;
                itm.stock_out = itm.stock_out *balance;
                if(index > -1){
                    items.value[index].stock_out = itm.stock_out;
                }else{
                   
                    items.value.push(itm);
                }
                
            });
            //items.value = itms.data.data;

        }
       
            
       /*const items =  await store.dispatch('app-stock/fetchStockData', {
                            is_active:1,
                            branch_id:branch_id,
                            order_by:'meterial_medicine.id DESC'
                        
                        })*/
        console.log('items_a',items);
        
    }
    fetchData();
    DoctorsData();
  

    const addStaff = (types)=>{
      Data.value.service_id = id.value
        //Data.value.service_id = Detail.value.service_id;
        Data.value.types = types;
        if(types == 'doctor'){
            
            Data.value.group_id = 7;
            Data.value.staffs = doctors.value.map((val)=>{
                return val.staff_id;
            });

        }else{
            Data.value.group_id = 8;
            Data.value.staffs = assistants.value.map((val)=>{
                return val.staff_id;
            });


        }
        isStaffFormSidebarActive.value = true;

    }
    const updatePrice = async(id,value)=>{
        console.log('updatePrice',id);
        console.log('value',value);
        let input = {
            price : value,
            id  : id
        }
        const response =  await store.dispatch('queue-store/updatePriceStaff',input);


    }
    const deleteStaff = async(id,types)=>{
        const response =  await store.dispatch('queue-store/deleteStaff',id);
        if(response.data.success){
            toast({
                component: ToastificationContent,
                props: {
                    title: "Delete Staff Success",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                },
            })
            if(types === 'assistant'){
                assistants.value = []
                assistantsData();
    
            }else{
                doctors.value = []
                DoctorsData();
            }

        }else{
            toast({
                component: ToastificationContent,
                props: {
                    title: "Delete Staff error",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })

        }
        //console.log('id',id);


    }
    const onSubmit = async ()=>{
        let data = {
            id:id.value,
            status:2,
            coureses: selecteds.value,
            patient_id:patient_id.value,
            branch_id:branch_id
        }
        console.log('selecteds',selecteds);
       const response =  await store.dispatch('queue-store/updateData',data);
       if(response.data.success){
        router.push({ name: 'queue-service', replace: true });
        toast({
            component: ToastificationContent,
            props: {
                title: "Update Success ",
                icon: 'AlertTriangleIcon',
                variant: 'success',
            },
        })
        
       }else{
        toast({
            component: ToastificationContent,
            props: {
                title: "Update Error ",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
        })

       }
    }
    //getItem();

    return {
        isItemFormSidebarActive,
        isItemNumFormSidebarActive,
        fetchOpdcardItemData,
        headertext,
        ItemData,
        addCourse,
        addCourseNum,
        Options,
        patient_id,
        fetchData,
        Detail,
        doctors,
        coursesData,
        coursesnumData,
        coureses,
        coureses_num,
        selecteds,
        removeCourse,
        changeQty,
        items,
        isStaffFormSidebarActive,
        id,
        Data,
        staffsData,
        assistants,
        addStaff,
        updatePrice,
        deleteStaff,
        onSubmit
    }
}